<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol md="4"> Laporan Kinerja </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <div class="row">
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <b for="">Perusahaan</b>
                      <v-select
                        :options="list_perusahaan"
                        label="p_nama"
                        v-model="perusahaan_selected"
                        :value="$store.myValue"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-md">
                    <div class="form-group">
                      <b for="">Tgl Dari</b>
                      <vuejs-datepicker
                        input-class="form-control no-readonly"
                        v-model="start"
                        format="dd MMM yyyy"
                      ></vuejs-datepicker>
                    </div>
                  </div>
                  <div class="col-md">
                    <div class="form-group">
                      <b for="">Tgl Sampai</b>
                      <vuejs-datepicker
                        input-class="form-control no-readonly"
                        v-model="end"
                        format="dd MMM yyyy"
                      ></vuejs-datepicker>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b for="">Tipe Permohonan</b>
                      <v-select
                        :options="filter_tipe"
                        :reduce="(label) => label.value"
                        label="label"
                        v-model="is_pemasukan"
                        :value="$store.myValue"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <b for="">Module Permohonan</b>
                      <v-select
                        :options="filter_module"
                        :reduce="(label) => label.value"
                        label="label"
                        v-model="prs_kategori"
                        :value="$store.myValue"
                      ></v-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-5 d-flex justify-content-start"
                style="margin-bottom: 10px; gap: 10px"
              >
                <div class="btn btn-sm btn-primary" v-on:click="filterData()">
                  <i class="fa fa-filter"></i> Filter
                </div>

                <!-- <div class="btn btn-sm btn-success" v-on:click="">
                  <i class="fa fa-download"></i> Excel
                </div> -->
              </div>
            </div>
            <CRow>
              <CCol sm="12">
                <CRow>
                  <CCol sm="12">
                    <template>
                      <div>
                        <table class="table table-sm table-bordered">
                          <thead class="bg-dark text-white">
                            <tr>
                              <th rowspan="2">No</th>
                              <th rowspan="2">Kode Permohonan</th>
                              <th rowspan="2">Kategori</th>
                              <th rowspan="2">NIB</th>
                              <th rowspan="2">Perusahaan</th>
                              <th rowspan="2">Tanggal Pengajuan</th>
                              <th colspan="6" class="text-center">
                                Kegiatan Tugas Jabatan
                              </th>
                            </tr>
                            <tr>
                              <th>Tugas</th>
                              <th>Jabatan</th>
                              <th>Estimasi</th>
                              <th>Tanggal proses</th>
                              <th>Telah diproses</th>
                              <th>Hasil</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="loadingTable">
                              <td colspan="12">
                                <i class="fa fa-spin fa-refresh"></i> Loading...
                              </td>
                            </tr>
                            <template
                              v-else-if="
                                reslistrealisasi && reslistrealisasi.length != 0
                              "
                            >
                              <tr
                                v-for="(permohonan, index) in reslistrealisasi"
                                :key="index"
                              >
                                <td>{{ index + 1 }}</td>
                                <td>{{ permohonan.kode }}</td>
                                <td>
                                  {{
                                    permohonan.is_pemasukan
                                      ? "Pemasukan"
                                      : "Pengeluaran"
                                  }}
                                  {{ permohonan.pr_kategori }}
                                </td>
                                <td>{{ permohonan.nib }}</td>
                                <td>
                                  {{
                                    permohonan.perusahaan
                                      ? permohonan.perusahaan.p_nama
                                      : ""
                                  }}
                                </td>
                                <td>{{ permohonan.pr_tgl_pengajuan }}</td>
                                <td colspan="6">
                                  <table class="table table-sm">
                                    <tr
                                      v-for="(
                                        status, statusIndex
                                      ) in permohonan.matchingStatuses"
                                      :key="statusIndex"
                                    >
                                      <td>{{ status.st_nama }}</td>
                                      <td>
                                        {{
                                          status.roles
                                            ? status.roles.name
                                            : "Tidak ada data"
                                        }}
                                      </td>
                                      <td>
                                        {{ status.estimasi_hari }} Hari kerja
                                      </td>
                                      <td>
                                        {{
                                          status.timeline_detail
                                            ? status.timeline_detail.pt_tanggal
                                            : "Tidak ada data"
                                        }}
                                      </td>
                                      <td>{{ status.processing_time }}</td>
                                      <td>
                                        {{
                                          status.timeline_detail
                                            ? status.timeline_detail
                                                .pt_keterangan
                                            : ""
                                        }}
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </template>
                            <tr v-else>
                              <td colspan="12">Data tidak tersedia</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </template>
                    <nav aria-label="pagination">
                      <ul class="pagination justify-content-start">
                        <li
                          v-for="(page, key_page) in paging"
                          :key="key_page"
                          :class="[
                            'page-item',
                            { active: page.active, disabled: !page.url },
                          ]"
                        >
                          <a
                            href="#"
                            @click.prevent="page.url && toPage(page.url)"
                            class="page-link"
                          >
                            <span v-html="page.label"></span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <br />
            <!-- form Filter PDF -->
            <!-- form Filter PDF selesai -->
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
const datalaporan = [];
const null_array = [];
export default {
  name: "LaporanKinerja",
  components: {
    vuejsDatepicker,
  },
  data() {
    return {
      reslistrealisasi: datalaporan,
      paging: [],
      show_data_to: "",
      show_data_from: "",
      total_data: "",
      curent_page: "",
      loadingTable: false,
      session_data: JSON.parse(this.session),
      is_pemasukan: "",
      start: "",
      end: "",
      status: "",
      excel_url: "",
      list_perusahaan: "",
      perusahaan_selected: "",
      prs_kategori: [],
      filter_module: [
        {
          value: "Hortikultura",
          label: "Hortikultura",
        },
        {
          value: "Sumber Daya Genetik",
          label: "Sumber Daya Genetik",
        },
        {
          value: "Tanaman Pangan",
          label: "Tanaman Pangan",
        },
        {
          value: "Perkebunan",
          label: "Perkebunan",
        },
        {
          value: "Benih Tanaman Pakan Ternak",
          label: "Benih Tanaman Pakan Ternak",
        },
      ],
      filter_tipe: [
        {
          value: "1",
          label: "Pemasukkan Benih Tanaman",
        },
        {
          value: "0",
          label: "Pengeluaran Benih Tanaman",
        },
      ],
    };
  },
  methods: {
    toPage(urlString) {
      // Membuat objek URL
      let url = new URL(urlString);

      // Menggunakan URLSearchParams untuk mengakses parameter query
      let searchParams = new URLSearchParams(url.search);

      // Mendapatkan nilai dari parameter 'page'
      let page = searchParams.get("page");
      console.log("page : ", page);
      this.loadingTable = true;
      axios
        .get(this.apiLink + "api/laporan/laporan-kinerja-pegawai", {
          params: {
            nib: this.perusahaan_selected?.nib,
            start: this.start,
            end: this.end,
            prs_kategori: this.prs_kategori,
            is_pemasukan: this.is_pemasukan,
            page: page,
          },
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          var res_realisasi = response.data;
          var res_realisasi_data = res_realisasi.data;
          // console.log(res_realisasi_data);
          if (res_realisasi.meta.code != "200") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res_realisasi.data.message,
            });
          } else {
            this.loadingTable = false;
            this.reslistrealisasi = res_realisasi_data.data.data;
            this.paging = res_realisasi_data.data.links;
            this.show_data_from = res_realisasi_data.data.from;
            this.show_data_to = res_realisasi_data.data.to;
            this.total_data = res_realisasi_data.data.total;
            this.curent_page = res_realisasi_data.data.current_page;
          }
        });
    },
    filterData() {
      this.loadingTable = true;
      let session_data = JSON.parse(this.session);
      axios
        .get(this.apiLink + "api/laporan/laporan-kinerja-pegawai", {
          params: {
            nib: this.perusahaan_selected?.nib,
            start: this.start,
            end: this.end,
            prs_kategori: this.prs_kategori,
            is_pemasukan: this.is_pemasukan,
          },
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          var res_realisasi = response.data;
          var res_realisasi_data = res_realisasi.data;
          if (res_realisasi.meta.code != "200") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res_realisasi.data.message,
            });
          } else {
            this.loadingTable = false;
            this.reslistrealisasi = res_realisasi_data.data.data;
            this.paging = res_realisasi_data.data.links;
            this.show_data_from = res_realisasi_data.data.from;
            this.show_data_to = res_realisasi_data.data.to;
            this.total_data = res_realisasi_data.data.total;
            this.curent_page = res_realisasi_data.data.current_page;
          }
        });
    },
    // getExcel() {
    //   console.log("excel url :", this.excel_url);
    //   // alert(this.excel_url);
    //   // return false;
    //   var win = window.open(this.excel_url, "_blank");
    //   if (win) {
    //     //Browser has allowed it to be opened
    //     win.focus();
    //   } else {
    //     //Browser has blocked it
    //     alert("Please allow popups for this website");
    //   }
    // },
    // getPDFLink() {
    //   if (this.start_pdf === "") {
    //     Swal.fire({
    //       icon: "error",
    //       title: "Oops...",
    //       text: "Silahkan masukan tanggal filter",
    //     });
    //     return;
    //   } else if (this.end_pdf === "") {
    //     Swal.fire({
    //       icon: "error",
    //       title: "Oops...",
    //       text: "Silahkan masukan tanggal filter",
    //     });
    //     return;
    //   }

    //   this.buttonLabel = "Downloading data...";
    //   this.isDownloading = true;
    //   let fileName = "Laporan SIP.pdf";

    //   axios
    //     .get(this.apiLink + "api/permohonan_realisasi/pdf_realisasi", {
    //       params: {
    //         nib: this.perusahaan_selected?.nib,
    //         start: this.start_pdf,
    //         end: this.end_pdf,
    //         prs_kategori: this.prs_kategori,
    //       },
    //       responseType: "blob",
    //       headers: {
    //         Authorization: "Bearer " + this.access_token,
    //       },
    //     })
    //     .then((response) => {
    //       if (response.status != 200) {
    //         Swal.fire({
    //           icon: "error",
    //           title: "Oops...",
    //           text: "Data tidak ditemukan",
    //         });
    //       } else {
    //         const fileURL = window.URL.createObjectURL(
    //           new Blob([response.data])
    //         );
    //         const fileLink = document.createElement("a");
    //         fileLink.href = fileURL;
    //         fileLink.setAttribute("download", fileName);
    //         document.body.appendChild(fileLink);
    //         fileLink.click();
    //         document.body.removeChild(fileLink);
    //       }
    //       this.buttonLabel = "Export PDF";
    //       this.isDownloading = false;
    //     })
    //     .catch((error) => {
    //       console.error("Error during PDF download", error);
    //       this.buttonLabel = "Export PDF";
    //       this.isDownloading = false;
    //     });
    // },
  },
  beforeCreate() {
    //   let session_data = JSON.parse(this.session);
    //   axios
    //     .get(this.apiLink + "api/permohonan_realisasi/permohonan_all", {
    //       params: {
    //         // nib: session_data.nib,
    //       },
    //       headers: {
    //         Authorization: "Bearer " + this.access_token,
    //       },
    //     })
    //     .then((response) => {
    //       var res_realisasi = response.data;
    //       var res_realisasi_data = res_realisasi.data;
    //       // console.log(res_realisasi_data);
    //       if (res_realisasi.meta.code != "200") {
    //         Swal.fire({
    //           icon: "error",
    //           title: "Oops...",
    //           text: res_realisasi.data.message,
    //         });
    //       } else {
    //         // let raw_realisasi = [];
    //         // $.each(res_realisasi_data, function(index_realisasi, val_realisasi) {
    //         //      $.each(val_realisasi.permohonan_realisasi_benih, function(index_benih, val_benih) {
    //         //         val_benih.realisasi = val_realisasi;
    //         //         raw_realisasi.push(val_benih);
    //         //      });
    //         // });
    //         // console.log(raw_realisasi);
    //         this.loadingTable = false;
    //         this.reslistrealisasi = res_realisasi_data.data.data;
    //         //   this.excel_url =
    //         //     "https://simpel2.pertanian.go.id/endpoint/public/api/excel_realisasi" +
    //         //     res_realisasi_data.excel;
    //         this.excel_url =
    //           this.apiLink + "api/excel_realisasi" + res_realisasi_data.excel;
    //         this.paging = res_realisasi_data.data.links;
    //         console.log(this.reslistrealisasi);
    //         // this.show_data_from = res_realisasi_data.from;
    //         // this.show_data_to = res_realisasi_data.to;
    //         // this.total_data = res_realisasi_data.total;
    //         // this.curent_page = res_realisasi_data.current_page;
    //       }
    //     });
    //   // Get Master Tanaman
    axios
      .get(this.apiLink + "api/master/perusahaan", {
        params: {},
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_perusahaan = response.data;
        var res_perusahaan_data = res_perusahaan.data;
        if (res_perusahaan.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_perusahaan.data.message,
          });
        } else {
          this.list_perusahaan = res_perusahaan_data.master;
        }
      });
  },
  mounted() {
    $(".no-readonly").prop("readonly", false);
  },
  // watch: {
  //   start_pdf(val) {
  //     if (val) {
  //       const start = new Date(val);
  //       const end = new Date(start);
  //       end.setDate(start.getDate() + 31);

  //       this.disabledDates = {
  //         ranges: [
  //           {
  //             from: new Date(-8640000000000000),
  //             to: new Date(
  //               start.getFullYear(),
  //               start.getMonth(),
  //               start.getDate()
  //             ),
  //           },
  //           {
  //             from: new Date(end.getFullYear(), end.getMonth(), end.getDate()),
  //             to: new Date(8640000000000000),
  //           },
  //         ],
  //       };
  //     }
  //   },
  // },
};
</script>
